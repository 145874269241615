<template>
  <div>
    <div class="hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-72 lg:flex-col">
      <TheSidebar @show-notifications="showNotifications" />
    </div>

    <div class="sticky top-0 z-10 lg:hidden">
      <TheMobileNavbar @show-notifications="showNotifications" />
    </div>

    <main class="min-h-dvh bg-gray-50 py-8 lg:pl-72">
      <div class="px-4 sm:px-6 lg:px-8">
        <slot />
      </div>
    </main>

    <NotificationsModal :open="notificationsOpen" @close="hideNotifications" />
  </div>
</template>

<script lang="ts" setup>
const notificationsOpen = ref<boolean>(false)

function showNotifications() {
  notificationsOpen.value = true
}

function hideNotifications() {
  notificationsOpen.value = false
}
</script>
